import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

const Footer = () => (
	<footer class={style.footer}>
		<h4>&copy; {new Date().getFullYear()} wrixton.xyz</h4>
	</footer>
);

export default Footer;
