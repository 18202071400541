import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

const Header = () => (
	<header class={style.header}>
		<a href="/" class={style.logo}>
			<img src="../../assets/logo.svg" alt="Logo" height="32" width="32" />
			<h1>Wrixton.xyz</h1>
		</a>
		<nav>
			<Link activeClassName={style.active} href="/">
				Home
			</Link>
			<Link activeClassName={style.active} href="/blog">
				Blog
			</Link>
			<Link activeClassName={style.active} href="/beer">
				Kegorator
			</Link>
		</nav>
	</header>
);

export default Header;
