import { h } from 'preact';
import { Route, Router } from 'preact-router';

import Header from './header';
import Footer from './footer';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Blog from '../routes/blog';
import Kegorator from '../routes/kegorator';

const App = () => (
	<div id="app">
		<Header />
		<main>
			<Router>
				<Route path="/" component={Home} />
				<Route path="/blog/" component={Blog} />
				<Route path="/beer/" component={Kegorator} />
			</Router>
		</main>
		<Footer />
	</div>
);

export default App;
